import React from 'react';

import $ from 'jquery';
import fetchApi from '@/api';
import { domain } from '@/config';

import './CallusLayer.scss';

export default class CallusLayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      isSuccess: false,

      validName: true,
      validAgent: true,
      validNumber: true,
      validEmail: true,
      validAll: false

    };
  }

  callus = (vkey) => {
    let name = this.getValueByName('name');
    let agent = this.getValueByName('agent');
    let number = this.getValueByName('number');
    let email = this.getValueByName('email');
    let textarea = this.getValueByName('textarea');
    let emailObj = {
      fromName: 'RAYS官网',
      subject: 'RAYS蓝海 6.0--新客户留言',
      html: true,
      text: `<div>
      <p>您好！${agent}公司的${name}客户对RAYS蓝海产品很感兴趣，有意向与您进行深度的沟通并建立合作关系。资料如下：</p>
      <p>姓名：${name}</p>
      <p>出版单位：${agent}</p>
      <p>联系电话：${number}</p>
      <p>邮箱：${email}</p>
      <p>留言: ${textarea} </p>
      <div>请您在24小时内与他联系，并进行友好的沟通。预祝你们能达成合作！</div>
    </div>`
    };
    let messageObj = {
      companyName: agent,
      customerName: name,
      phoneNum: number
    };

    // if(!name && !agent && !number && !email){
    //   return;
    // }

    let validSuccess = true;

    if(!name && ((!vkey) || vkey === 'name')) {
      //alert('姓名不能为空')
      if(!vkey) this.setState({
        validName: false
      });
      validSuccess = false;
    }
    if(!agent && (!vkey || vkey === 'agent')) {
      //alert('出版社不能为空')
      if(!vkey) this.setState({
        validAgent: false
      });
      validSuccess = false;
    }
    if(!(new RegExp('^1[3|4|5|7|8][0-9]{9}$').test(number)) && (!vkey || vkey === 'number')) {
      //alert('请输入正确的手机号')
      if(!vkey) this.setState({
        validNumber: false
      });
      validSuccess = false;
    }
    if(!(new RegExp('^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$').test(email)) && (!vkey || vkey === 'email')) {
      //alert('请输入正确的邮箱');
      if(!vkey) this.setState({
        validEmail: false
      });
      validSuccess = false;
    }

    if(validSuccess) {
      this.setState({
        validAll: true
      });

      if(!vkey || vkey === 'name') {
        this.setState({
          validName: true
        });
      }
      if(!vkey || vkey === 'agent') {
        this.setState({
          validAgent: true
        });
      }
      if(!vkey || vkey === 'number') {
        this.setState({
          validNumber: true
        });
      }
      if(!vkey || vkey === 'email') {
        this.setState({
          validEmail: true
        });
      }

      if(vkey) {
        return;
      }

      let nowTime = new Date().getTime();
      let nextClickTime = null;
      if(!window.localStorage.getItem('clickTime')) {
        window.localStorage.setItem('clickTime', new Date().getTime());
      } else {
        nextClickTime = window.localStorage.getItem('clickTime');
      }
      if(parseInt(((nowTime - nextClickTime) / 1000) / 60) < 5) {
        alert('您刚才的反馈已收到，我们正在安排处理');
      } else {
        window.localStorage.setItem('clickTime', new Date().getTime());

        const isProDomain = ['5rs.me', 'dcrays.cn', 'dcrays.com'].includes(domain);
        const sendNodeEmailsParams = isProDomain ? { ...EMAIL_LIST, ...emailObj } : { ...EMAIL_LIST_TEST, ...emailObj };
        fetchApi.sendNodeEmails(sendNodeEmailsParams);

        if(isProDomain) {
          fetchApi.sendMessage(Object.assign({}, messageObj, { toPhoneNum: '18627916333' }));
          fetchApi.sendMessage(Object.assign({}, messageObj, { toPhoneNum: '18271426735' }));
          fetchApi.sendMessage(Object.assign({}, messageObj, { toPhoneNum: '13006399323' }));
          fetchApi.sendMessage(Object.assign({}, messageObj, { toPhoneNum: '15800830616' }));
          fetchApi.addTourist({
            'agentName': messageObj.companyName,
            'name': messageObj.customerName,
            'phone': messageObj.phoneNum
          });
        }
        // 测试好好联系方式
        const sendPhone = isProDomain ? '15926459426' : '15871494529';
        fetchApi.sendMessage(Object.assign({}, messageObj, { toPhoneNum: sendPhone })).then(() => {
          this.setState({
            isSuccess: true
          });
          //alert('提交成功')
        }).catch(() => {
          alert('网络不稳定，请稍后重试, 或者直接打电话沟通！');
        });
      }
    } else {
      this.setState({
        validAll: false
      });
    }

  }

  getValueByName = (name) => {
    return document.getElementsByName(name)[0].value;
  }

  onBlur = (params) => {
    document.querySelectorAll('.form-item').forEach((v) => {
      v.style.color = '#999';
    });
    this.callus(params);
    switch (params) {
      case 'name':
        if(!this.getValueByName('name')) {
          $('.name-box-title').removeClass('active').css({ color: '#999' }).addClass('back');
        }
        break;
      case 'number':
        if(!this.getValueByName('number')) {
          $('.number-box-title').removeClass('active').css({ color: '#999' }).addClass('back');
        }
        break;
      case 'agent':
        if(!this.getValueByName('agent')) {
          $('.agent-box-title').removeClass('active').css({ color: '#999' }).addClass('back');
        }
        break;
      case 'email':
        if(!this.getValueByName('email')) {
          $('.email-box-title').removeClass('active').css({ color: '#999' }).addClass('back');
        }
        break;
    }
    if(!this.getValueByName(params)) $('.' + params + '-box-title').text('请输入' + $('.' + params + '-box-title').text());
  }

  onFocus = (params) => {
    $('.' + params + '-box-title').addClass('active').css({ color: '#5471fe' });

    $('.' + params + '-box-title').text($('.' + params + '-box-title').text().replace('请输入', ''));
  }

  componentDidMount() {

  }

  render() {
    const { tabIndex, isSuccess, validName, validAgent, validNumber, validEmail, validAll } = this.state;
    const { onModalClick } = this.props;
    return (
      <div className="CallusLayer">
        <i className="iconfont iconcha" onClick={() => onModalClick && onModalClick()} />
        <div className="qr_code-wrapper">
          <div className="code-item">
            <img className="icon-qq" src="https://oss.5rs.me/oss/uploadfe/png/9fb5366a7f08dafc445cc06eab97b0a4.png" />
            <div className="code-wrapper">
              <img src="https://oss.5rs.me/oss/uploadfe/png/093f098541e6dc9d62a4fa3bc5f371b3.png" className="code" />
            </div>
          </div>
          <div className="code-item">
            <img className="icon" src="https://oss.5rs.me/oss/uploadfe/png/b219af10fa3b3f8866d6bf1ebedbc321.png" />
            <div className="code-wrapper">
              <img src="https://oss.5rs.me/oss/uploadfe/png/d802aab3a429785a4a06eb95aa787c3d.png" className="code" />
            </div>
          </div>
        </div>
        <img src={LEFT_ICON} className="left-icon" />
        <div className="login-box">

          {isSuccess && <div className="success-box">
            <img src={require('../../assets/images/icon_上传成功.png').default} />
            <h5>信息提交成功</h5>
            <p>我们会尽快联系你</p>
          </div>

          }

          {!isSuccess && <form id="login-form">
            <p className="login-form-title">填写下方资料，我们将尽快跟您联系。</p>
            <div className="form-item">
              <label className="label">姓名</label>
              <div className={'put-wrapper name-box' + (validName ? '' : ' valid-fail')}>
                <input
                  type="text"
                  autoComplete="off"
                  className="put name-box-input"
                  placeholder="请输真实姓名"
                  name="name"
                  onBlur={() => this.onBlur('name')}
                  onFocus={() => this.onFocus('name')}
                />
                <small style={{ opacity: validName ? 0 : 1 }}>姓名不能为空</small>
              </div>
            </div>
            <div className="form-item">
              <label className="label">出版社</label>
              <div className={'put-wrapper agent-box' + (validAgent ? '' : ' valid-fail')}>
                <input
                  type="text"
                  autoComplete="off"
                  className="put agent-box-input"
                  placeholder="请输入出版社名称"
                  name="agent"
                  onBlur={() => this.onBlur('agent')}
                  onFocus={() => this.onFocus('agent')}
                />
                <small style={{ opacity: validAgent ? 0 : 1 }}>出版社不能为空</small>
              </div>
            </div>
            <div className="form-item">
              <label className="label">手机</label>
              <div className={'put-wrapper number-box' + (validNumber ? '' : ' valid-fail')}>
                <input
                  type="text"
                  autoComplete="off"
                  className="put number-box-input"
                  placeholder="请输入手机号码"
                  name="number"
                  onBlur={() => this.onBlur('number')}
                  onFocus={() => this.onFocus('number')}
                />
                <small style={{ opacity: validNumber ? 0 : 1 }}>请输入正确的手机号</small>
              </div>
            </div>
            <div className="form-item">
              <label className="label">邮箱</label>
              <div className={'put-wrapper email-box' + (validEmail ? '' : ' valid-fail')}>
                <input
                  type="email"
                  autoComplete="off"
                  className="put email-box-input"
                  placeholder="请输入邮箱"
                  name="email"
                  onBlur={() => this.onBlur('email')}
                  onFocus={() => this.onFocus('email')}
                />
                <small style={{ opacity: validEmail ? 0 : 1 }}>请输入正确的邮箱</small>
              </div>
            </div>
            <div className="textarea-box">
              <textarea placeholder="请留下您想说的话和宝贵建议。" className="textarea-box-input" name="textarea" />
            </div>
            <div className="submit-wrapper">
              <div
                style={{ background: validAll ? '' : '#878787' }}
                className="submit text-center"
                dataUrl={LOGIN_LIST[tabIndex].pcUrl}
                onClick={() => this.callus('')}
              >提交
              </div>
            </div>
          </form>}

        </div>
      </div>
    );
  }
}

const LEFT_ICON = 'https://oss.5rs.me/oss/uploadfe/png/bc45f3b1c1003c68d4ace52bdc58adea.png';

const LOGIN_LIST = [
  {
    pcName: '出版端',
    pcUrl: 'http://ra.5rs.me/'
  },
  {
    pcName: '运营端',
    pcUrl: 'https://rc.5rs.me/'
  },
  {
    pcName: '作者端',
    pcUrl: 'https://rm.5rs.me/'
  },
  {
    pcName: '编辑端',
    pcUrl: 'https://adviser.5rs.me/'
  }
];

const EMAIL_LIST = {
  to: [
    '雷彬<leibin@dcrays.cn>',
    '华程程<huachengcheng@dcrays.cn>'
  ],
  cc: [
    '施总<100983312@qq.com>',
    '苏洁华<sujiehua@dcrays.cn>',
    '朱家玮<zhujiawei@dcrays.cn>',
    '韩双力<hanshuangli@dcrays.cn>'
  ]
};

const EMAIL_LIST_TEST = {
  to: [
    '韩双力<hanshuangli@dcrays.cn>'
  ],
  cc: [
    '夏斌<xiabin@dcrays.cn>',
    '郑好好<zhenghaohao@dcrays.cn>'
  ]
};

