import React from 'react';
// import $ from "jquery";
import md5 from 'md5';

import Utils from '@/utils/utils';
import { domain } from '@/config';
import { addTrack, TRACK_TYPE } from '@/business/spider';

import './Login.scss';
import classNames from 'classnames';

export default class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      userName: '',
      password: '',
      init: true
    };
  }

  login = () => {
    const { tabIndex } = this.state;
    let account = this.getValueByName('account');
    let password = this.getValueByName('password');
    if(!account || !password) {
      this.setState({
        init: false
      });
      return;
    } else {
      const system = LOGIN_LIST[tabIndex].pcName;
      addTrack(TRACK_TYPE.CLICK, '33', `官网PC端用户登录_${system}`);

      let enWord = Utils.encrypt(`user=${encodeURIComponent(account)}&pwd=${md5(password)}&startMs=${Date.now()}&durMs=${1000 * 60}`);
      let url = `${LOGIN_LIST[tabIndex].pcUrl}?encryptInfo=${enWord}&encrypt=aes`;
      window.open(url, '_blank');
    }
  }

  registerTrack = () => {
    const { tabIndex } = this.state;
    const system = LOGIN_LIST[tabIndex].pcName;
    addTrack(TRACK_TYPE.CLICK, '33', `官网PC端用户注册_${system}`);
    window.open(`${LOGIN_LIST[tabIndex].pcUrl}register`, '_blank');
  }

  getValueByName = name => {
    return document.getElementsByName(name)[0].value;
  }

  changeTab = index => {
    this.setState({
      tabIndex: index
    });
  }

  onChange = (key, e) => {
    let value = e.target.value;
    this.setState({
      [key]: value
    });
  }

  onKeyDown = e => {
    if(e.keyCode === 13) {
      e.preventDefault();
      this.login();
    }
  }

  render() {
    const { tabIndex, init, userName, password } = this.state;
    console.log('init', init, userName, password);
    return (
      <div className="Login">
        <i
          className="close-icon"
          onClick={() => this.props.onModalClick && this.props.onModalClick()}
        />
        <div className="wx-login">
          <div className="wx-login-content">
            <p className="wx-login-contentTitle">安全登录</p>
            <p className="wx-login-contentItem">Hello~编辑们！</p>
            <p className="wx-login-contentItem">美好的一天 从RAYS开始</p>
          </div>
          <div className="wx-qrCode-wrapper">
            <div className="wx-qrCode" />
          </div>
          <div className="login-text">微信端扫码登录</div>
          {/* <div className="wx-bottomLeft" /> */}
        </div>
        <div className="login-box">
          <ul className="login-list">
            {LOGIN_LIST.map((v, index) => {
              return (
                <li
                  className={`login-item ${tabIndex === index ? 'active' : ''}`}
                  onClick={() => this.changeTab(index)}
                  key={index}
                >
                  {v.pcName}
                  <div className="" />
                </li>
              );
            })}
          </ul>
          <form
            id="login-form"
            onSubmit={this.login}
            onKeyDown={this.onKeyDown}
          >
            <p className="login-form-title">PC端用户登录</p>
            <div className="put-box">
              <div className="label">账号</div>
              <div className={classNames('put-wrapper', { 'fail': (!init && !userName) })}>
                <input
                  type="text"
                  autoComplete="off"
                  className="put account-box-input"
                  placeholder="请输入您的账号"
                  name="account"
                  onChange={(e) => this.onChange('userName', e)}
                />
                <p>账号不能为空</p>
              </div>
            </div>
            <div className="put-box">
              <div className="label">密码</div>
              <div className={classNames('put-wrapper', { 'fail': !init && !password })}>
                <input
                  type="password"
                  autoComplete="off"
                  placeholder="请输入您的密码"
                  className="put password-box-input"
                  name="password"
                  onChange={(e) => this.onChange('password', e)}
                />
                <p>密码不能为空</p>
              </div>
            </div>
            <div className="submit text-center" style={{ background: password && userName && 'linear-gradient(147deg, #2082C1 0%, #124089 100%)' }} onClick={() => this.login()}>
              提交
            </div>
            <p className="no-account text-center">
              <span>没有账号？</span>
              <a onClick={this.registerTrack}>立即注册</a>
            </p>
          </form>
        </div>
      </div>
    );
  }
}
const host = domain === 'dcrays.cn' ? '5rs.me' : domain;

const LOGIN_LIST = [
  {
    pcName: '出版端',
    pcUrl: `https://ra.${host}/`
  },
  {
    pcName: '运营端',
    pcUrl: `https://rc.${host}/`
  },
  {
    pcName: '作者端',
    pcUrl: `https://rm.${host}/`
  },
  {
    pcName: '编辑端',
    pcUrl: `https://adviser.${host}/`
  }
];
